import * as React from 'react';
// import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/Share';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Image } from 'mui-image';
import { Container, Box, Button, Stack, Typography, Link } from '@mui/material';
import globalParams from './global';
import QAI from './qai';

export default function myApp(){

const { myQai, setmyQai, fetchMyData, St2images, Rateit, FormGender } = QAI();

const getSrvUpdates = ()=>{
    // localStorage.setItem('qai', '{}');
    // window.location.href = './';
    const ndt =new Date().getTime();
    const odt = myQai.dt || ndt;
    if ((ndt-odt)>600){
    let myQai2 = JSON.parse(JSON.stringify(myQai));
    fetchMyData(globalParams.apiUrl,myQai2,
    (newData)=>{
       let myNewData = newData || {};
       myNewData.dt = ndt;
       setmyQai(myNewData);
    }
    );
    }
}

const ViewURLFormula = ()=>{
    if (myQai?.mpfj?.["formula-name"]) {
    return (
        <div>
            <Typography variant='h6'>View {myQai?.mpfj?.["formula-name"] || "MyFormula"} Formula?</Typography>
            <div style={{paddingTop:"20px",paddingBottom:"20px"}}>
            <Button variant="outlined" color="aiq" size="large" endIcon={<SendIcon />} onClick={() => {doContinue(3);}}>Continue</Button>
            </div>
        </div>
    );
    }
    else {
    return (null);    
    }
}

const myScrolly = ()=>{
    setTimeout(()=>{
      const element = document.getElementById('root');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }},200);
  }

const sendRating = ()=>{
    myQai.stages = [];
    window.history.pushState('page2', '', `/?mpf=${myQai.mpf}`);
    if (typeof dataLayer !== 'undefined') {
        window.dataLayer.push({ 'event': 'GAEvent', 'eventCategory': 'AIQ23a', 'eventAction': 'Quiz Completed R', 'eventLabel':`${(myQai?.rating?.rate1 || 0).toString()} ${(myQai?.rating?.rate2 || 0).toString()}` });
        window.dataLayer.push({ 'event': 'GAEvent', 'eventCategory': 'AIQ23a', 'eventAction': `Quiz Completed ${(myQai?.stage || 0).toString()}`, 'eventLabel':myQai?.qGender || "NAGender" });
    }
}

function doContinue(stage,myStr = ""){
    const mySiteLink = globalParams.shareUrl;
    if (stage===88) {
        if (typeof dataLayer !== 'undefined') {
        window.dataLayer.push({ 'event': 'GAEvent', 'eventCategory': 'AIQ23a', 'eventAction': `Quiz Abandon Logo`, 'eventLabel':"Logo Clicked" });
        }
        setTimeout(() => {
        window.location.href = `${globalParams.logoUrl}`;
        return;
        }, 300);
    }
    if (stage > 90) {myQai.stage = stage; getSrvUpdates(); sendRating();}
    //if (stage===91) {myQai.stage = stage; getSrvUpdates();}
    if (stage===98) {window.location.href = `mailto:?subject=AI%20Quiz%20-%20${encodeURIComponent(myQai?.mpfj?.["formula-name"] || "MyFormula")}&body=${myStr}%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0D%0A%0D%0AHere%20is%20your%20flower's%20link:%0D%0A%0D%0A${mySiteLink}%3D${encodeURIComponent(myQai?.mpf)}%0D%0A%0D%0AGet%20Balanced%20with%20your%20Personal%20Bach%20Remedy%0D%0A%0D%0A`; return;}
    if (stage===99) {
        setTimeout(() => {
        window.location.href = `${globalParams.orderUrl}?fnpPFormula=${myQai?.mpf}`; 
        return;
        }, 300);
    }
    if (stage > 90 && stage < 99) {myQai.stage = 1; stage = 1;}
    if (myQai.stage !== stage || myStr === "reload"){
    myQai.stage = stage;
    const myQai2 = JSON.stringify(myQai);
    setmyQai(JSON.parse(myQai2));
    }
}

if (!myQai?.stage){return null;}
let myStage = myQai?.stage || 1;

myScrolly();

if (myStage === 1){

return (
<div>
<Container sx={{display:"flex",justifyContent: "center",flexWrap: "nowrap","alignItems":"center",flexDirection: "column", padding: 1,"borderBottom":"1px dotted #f5f5f5"}}>
<Typography variant="h3" gutterBottom>Bach AI Quiz</Typography>
<ViewURLFormula />
<Link href="#" title="Bach Flowers - Get Balanced!" onClick={() => {doContinue(88);}}><Image src="https://cdn.bachflowers.com/images/fblogo3.png" style={{"width":"160px","height":"160px","borderRadius":"50%","padding":"2px"}} alt="Personal Remedy" /></Link>
<Typography variant="h4" sx={{p:1,color:"#fff",textShadow:"1px 1px 3px #000"}} gutterBottom>Get Balanced!</Typography>
<Typography variant="body1">
Discover personalized Bach flower remedies with our unique Quiz. <strong>In 3-5 minutes,</strong> find the perfect flowers to improve your life.
</Typography>

<Typography variant="body1" sx={{color:'#fff'}}>
In each quiz stage with two images, choose the one that resonates with your current emotions while staying objective and trusting your intuition.
</Typography>

</Container>
<FormGender />
<Typography variant='body1'>🌸 &nbsp;<strong>Limited time offer:</strong> Get 3 x 1oz. bottles that will last about 3 months of use for only <s style={{"color":"red"}}>$56.85</s> <span style={{"color":"green","font-weight":"bold"}}>$37.90</span> 100% natural and safe.</Typography>
<div>&nbsp;</div>
</div>
);
}

else if (myStage === 3){
getSrvUpdates();
if (!myQai?.mpr){
return(
    <div>
    <h1>Loading...</h1>
    <p>&nbsp;</p>
    <Button variant="outlined" color="aiq" size="large" endIcon={<SendIcon />} onClick={() => {myQai.stages=[]; window.location.reload();}}>Start Over</Button>
    </div>
 );
}
else {
window.history.pushState('page2', '', `/?mpf=${myQai.mpf}`);
if (typeof dataLayer !== 'undefined') {
    window.dataLayer.push({ 'event': 'GAEvent', 'eventCategory': 'AIQ23a', 'eventAction': `Quiz Completed ${(myQai?.stage || 0).toString()}`, 'eventLabel':myQai?.qGender || "NAGender" });
}
let myRemedy = [];
let myRemedyEmail = "My%20Bach%20Flowers:%0D%0A%0D%0A";
for (var i = 0; i < myQai.mpr.length; i++){
var myFlower = myQai?.mpr[i];
var myLang = (myQai?.mpfj["fnp-cust"] === "FB-715587") ? "he" : "en";
var myDescStyle = (myQai?.mpfj["fnp-cust"] === "FB-715587") ? {"direction":"rtl","color":"#000"} : {};
var myPicUrl = `https://cdn.bachflowers.com/flowers/${myFlower.pic}`;
var myFlowerLink = `https://www.bachflowers.com/collections/bach-flower-38s-essences/products/bach-${myFlower.flName.toLowerCase().replace(/\s/g,'-')}`;
myRemedy.push(<div key={i}><Typography variant="h4" color="primary" gutterBottom>{myFlower.flName}</Typography><Link href={myFlowerLink} target="_blank"><Image src={myPicUrl} style={{"width":"200px", "height": "200px","borderRadius":"50%","padding":"2px","border":"2px solid #fff"}} alt={myFlower.flName} /></Link><Typography variant="h5" color="secondary" style={myDescStyle} gutterBottom>{myFlower.flDesc}</Typography><hr/></div>);
myRemedyEmail+= `${encodeURIComponent(myFlower.flName)} - ${encodeURIComponent(myFlower.flDesc)} %0D%0A%0D%0A`;
}
var dontShow = 'block';
if (myRemedy.length===0 || myRemedy.length > 6) {dontShow = 'none'; myRemedy = []; myRemedy.push(<div key="dontShow"><Typography variant='h4' color='secondary'>It seems that you're fairly balanced.</Typography><Typography variant='body1'>We don't have any recommended formula at this time.</Typography></div>);}

return (
    <div>
    <Container maxWidth="md">
    <div>
    <Typography variant='h4' style={{"padding": "10px 5px"}} gutterBottom>{myQai?.mpfj?.["formula-name"] || "MyFormula"}</Typography>
    <Typography variant='h4' style={{"color":"#fff","textDecoration":"underline","textDecorationStyle":"solid","textDecorationColor":"#555","padding":"20px 5px","display":dontShow}}>{(myLang === "he") ? "תמצית באך אישית" : "My Bach Flowers:"}</Typography>
    <div>{myRemedy}</div>
    <br/>
    {
    (myLang !== "he") ?
    <div>
    <Typography variant="h5" sx={{p:1,color:"#fff",textShadow:"1px 1px 3px #000"}} gutterBottom>* To view the full description of a flower, simply click on its image. The description will open in a new window.</Typography>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" >
        <Rateit id="rate1" name="Please rate your overall experience" rate="0" />
        </Stack>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" style={{"display":dontShow}} >
        <Rateit id="rate2" name="Do the flowers reflect your current state of mind?" rate="0" />
        </Stack>
        <div>&nbsp;</div>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" style={{"display":dontShow}} >
            <Button variant="contained" color="success" size="large" endIcon={<SendIcon />} onClick={() => {doContinue(99);}}>
            Order My Remedy
            </Button>
        </Stack>
        <div>&nbsp;</div>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" >
            <Button variant="contained" color="aiq" size="large" endIcon={<SendIcon />} onClick={() => {doContinue(91,"reload");}}>
            Start a New Quiz
            </Button>
        </Stack>
    </div>
    :
    <div>
        <h3>אתם יכולים להזמין מהאתר הבא</h3>
        <a href='https://www.bachflower.co.il/?CategoryID=447&ArticleID=875'>BachFlower.co.il</a>
    </div>
    }
    <div>&nbsp;</div>
    <Box sx={{ display: { xs: 'block', md: 'none' }}}>
    <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" style={{"display":dontShow}}>
        <Button variant="contained" color="primary" size="large" startIcon={<ShareIcon />} endIcon={<ForwardToInboxIcon />} onClick={() => {doContinue(98,myRemedyEmail);}}>
        Share Formula
        </Button>
    </Stack>
    <Typography variant='body2' style={{"padding": "10px 5px"}} gutterBottom>* You can also share this formula via share buttons built into your iOS or Android smartphone.</Typography>
    </Box>
    </div>
    </Container>
    </div>
);
}
}

else if (myStage === 2){
getSrvUpdates();
return (
    <div>
        <St2images />
        <div style={{paddingTop:"120px"}}>
        <Button variant="outlined" color="aiq" size="large" endIcon={<SendIcon />} onClick={() => {myQai.stages=[]; doContinue(1);}}>Start Over</Button>
        </div>
    </div>
);
}

else {
    myQai.stage = 1;
    return (
        <div>
            <h1>Thank you!</h1>
            <Button variant="outlined" color="aiq" size="large" endIcon={<SendIcon />} onClick={() => {myQai.stages=[]; doContinue(1,"reload");}}>Start Over</Button>
        </div>
        );
}

}