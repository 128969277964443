const globalParams = {
"apiUrl": "https://api.bachflowers.com/aiq23", // https://api.bachflowers.com/aiq23   https://t1.8tnt.com/apidaka/aiq-q  https://fb.feelbach.com/apidaka/aiq-q
"shareUrl": "https://aiq.bachflowers.com/?mpf",
"orderUrl": "https://www.bachflowers.com/products/3-x-bottles-of-personal-remedy",
"logoUrl": "https://www.bachflowers.com/?r=aiq23l",
"fnptkn": "IcJOusdTvULN5D4Y7",
"gtmId": "GTM-P34HQMM",
"totalStages": 47 // (45 is the number of stages to fill)
};
export default globalParams; // test 24a
