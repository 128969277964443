//import './App.css';
import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MyApp from './myApp';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#666'
    },
    primary: {
      main: '#5e5d07'
    },
    success: {
      main: '#37a109'
    },
    aiq: {
      main: '#006633',
      light: '336633',
      dark: '#003300',
      contrastText: "#fff"
    },

  },
});

function App() {
return (
<div className="App">
<ThemeProvider theme={theme}>
<div>
<MyApp />
<div id="div2images2" style={{marginTop:"-200px",height:"100px"}}>&nbsp;</div>
<div style={{height:"100px"}}>&nbsp;</div>
<p>&nbsp;</p>
</div>
</ThemeProvider>
</div>
);

}

export default App;
