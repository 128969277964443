import { useState, useEffect } from 'react';
import { Image } from 'mui-image';
import SendIcon from '@mui/icons-material/Send';
import {Rating, Button, Grid, Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, FormHelperText, TextField, Typography, Link} from '@mui/material';
import globalParams from './global';

// const MyCustomSpinner = ()=>{
//   return (
//   <div>
//     {/* <Box sx={{
//         minWidth: 800,
//         minHeight: 800,
//         display: 'inline-block',
//         flexDirection: 'row',
//         backgroundColor: '#c1be1c',
//         m: 1,
//         p: 1
//     }}> */}
//       <Fade in={true}><Box><h1 style={{color: '#c5c379',display: 'block', margin: '120px auto'}}>Loading...</h1></Box></Fade>
//     {/* </Box> */}
//   </div>
//   );
// }
const QAI = (props)=> {
  const [myQai, setmyQai] = useState({});

  const Rateit = (props)=>{
    if (!myQai.rating) {myQai.rating = {};}
    if (!myQai.rating[props.id]) {myQai.rating[props.id] = Number(props.rate);}
    const [value, setValue] = useState(myQai?.rating[props.id]);
     return (
       <Box>
         <div style={{fontSize:"16pt",fontWeight:"bold",padding:"10px"}}>{props.name}</div>
         <Rating
           size="large"
           id={props.id+'-r'}
           name="simple-controlled"
           value={value} // myQai?.rating[props.id]
           onChange={(event, newValue) => {
            setValue(newValue);
            myQai.rating[props.id] = newValue;
           }}
         />
         {/* <input type='hidden' id={props.id} value={myQai.rating[props.id]}></input> */}
       </Box>
     );
   }

  const fetchMyData = async (myUrl,myBody,myFunc) => {
    if (Object.keys(myBody).length !== 0){
      const response = await fetch(`${myUrl}`, {
        method: 'post',
        body: JSON.stringify(myBody),
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json; charset=utf-8', 'fnptkn': globalParams.fnptkn },
      });
      const newData = await response.json();
      await myFunc(newData);
    }
  }

    useEffect(()=>{
      const myQai2 = JSON.parse(localStorage.getItem('qai')||'{}');
      if (Object.keys(myQai2).length !== 0){
        const mpf = (window.location.href+"?mpf=NAMPF").split("?mpf=")[1];
        if (mpf.length>10 && (myQai2.mpf !== mpf)) {myQai2.mpf = mpf; myQai2.stage = 3;}
        setmyQai(myQai2);
      }
      else {
        const rid = (window.location.href+"?r=NARID").split("?r=")[1];
        const mpf = (window.location.href+"?mpf=NAMPF").split("?mpf=")[1];
        const dt = new Date().getTime();
        let stg = 1;
        if (mpf.length > 10) {stg = 3;}
        fetchMyData(globalParams.apiUrl,{"stage":stg,"stages":[],"dt":dt,"fdt":dt,"rid":rid,"mpf":mpf},
        (newData)=>{
           setmyQai(newData);
        }
        );
      }
      return ()=>{
        //console.log("ue2");
      }
    },[]);
  
    useEffect(() => {
      if (Object.keys(myQai||{}).length !== 0){
          localStorage.setItem('qai', JSON.stringify(myQai));
      }
      return ()=>{
        //console.log("ue3");
      }
      }, [myQai]);

    const FormGender = ()=>{
      const [gender, setGender] = useState('');
      const [qName, setQName] = useState('');
      const [error, setError] = useState(false);
      const [helperText, setHelperText] = useState(' ');
      const handleRadioChange = (event) => {
        setGender(event.target.value);
        //setHelperText(' ');
        setHelperText(`Quiz for ${event.target.value}`);
        setError(false);
      };
    
      const handleNameChange = (event) => {
        setQName(event.target.value);
        //setQName(' ');
        setHelperText(`Quiz for ${event.target.value}`);
        setError(false);
      };
    
      const handleSubmit = (event) => {
          event.preventDefault();
          //myScrolly();
          if (gender?.length>0 && qName?.length>1) {
            setHelperText(`You got it ${gender}!`);
            setError(false);
            myQai.stage = 2;
            myQai.qGender = gender;
            myQai.qName = qName;
            const myQai2 = JSON.stringify(myQai);
            setmyQai(JSON.parse(myQai2));
          } 
          // else if (gender === 'Man') {
          //   setHelperText('Sorry, wrong answer!');
          //   setError(true);

          //   myQai.stage = 3;
          //   myQai.qGender = gender;
          //   myQai.qName = qName;
          //   const myQai2 = JSON.stringify(myQai);
          //   setmyQai(JSON.parse(myQai2));
          // } 
          else {
            setHelperText('Please select gender and name');
            setError(true);
          }
        };
    
    return (
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ m: 2 }} error={error} variant="standard">
          <FormLabel id="radioGender" sx={{color:"#000",textAlign:"left",fontWeight:"bold"}}>The Remedy is for a:</FormLabel>
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="quiz"
            value={gender}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="Woman" control={<Radio />} label="Woman" />
            <FormControlLabel value="Man" control={<Radio />} label="Man" />
            <FormControlLabel value="Girl" control={<Radio />} label="Girl" />
            <FormControlLabel value="Boy" control={<Radio />} label="Boy" />
          </RadioGroup>
          <TextField sx={{ mt: 1, mr: 1 }} id="formula-name" label="Name" variant="filled" color="secondary" value={qName} onChange={handleNameChange} />
          <FormHelperText>{helperText}</FormHelperText>
          <Button size="large" sx={{ mt: 1, mr: 1 }} type="submit" variant="contained" color="primary" endIcon={<SendIcon />}>
            Let's Get Started
          </Button>
        </FormControl>
      </form>
    );
    
}

  function St2images(){
    //myScrolly();
    // showLoading={<MyCustomSpinner />}
      const myStages = myQai.stages || [];
      if (myStages.length>1){
        const myFirstPic = myStages[myStages.length-2].pic1;
        const mySecondPic = myStages[myStages.length-2].pic2;
        const myThirdPic = myStages[myStages.length-1].pic1;
        const myFourthPic = myStages[myStages.length-1].pic2;
        const curStage = globalParams.totalStages-myStages.length;

          return (
            <div id="div2images">
            <div className='stage' id='curStage'>{curStage}</div>
            <Grid container spacing={{ xs: 0, md: 2, xl: 2 }} columns={{ xs: 6, sm: 8, md: 6, xl: 6 }}>
            <Grid item xs={6} sm={4} md={3} xl={3}>
            <div id='firstImage' className='gridiv'><Image src={`https://cdn.bachflowers.com/qai3/${myFirstPic}`} alt="" onClick={()=>St3images(myFirstPic)} shift="right" distance="3rem" shiftDuration={450} duration={1200} /></div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} xl={3}>
            <div id='secondImage' className='gridiv'><Image src={`https://cdn.bachflowers.com/qai3/${mySecondPic}`} alt="" onClick={()=>St3images(mySecondPic)} shift="left" distance="3rem" shiftDuration={600} duration={1200} /></div>
            </Grid>
            </Grid>

            <img className="imgPreload" key="preload1" src={`https://cdn.bachflowers.com/qai3/${myThirdPic}`} alt="preload1" />
            <img className="imgPreload" key="preload2" src={`https://cdn.bachflowers.com/qai3/${myFourthPic}`} alt="preload2" />

            </div>
          );
      }
      else {
        if (typeof dataLayer !== 'undefined') {
          window.dataLayer.push({ 'event': 'GAEvent', 'eventCategory': 'AIQ23a', 'eventAction': `Quiz Started ${(myQai?.stage || 0).toString()}`, 'eventLabel':myQai?.qGender || "NAGender" });
        }
        return (
          <div>
          <Typography variant="h2" style={{opacity:0.4,transition:"ease-in",color:"#fff",marginTop:"200px"}}>Loading...</Typography>
          <p>&nbsp;</p>
          <Link href="#" variant="h6" color="secondary" onClick={() => {myQai.stages=[]; window.location.reload();}}>Reload</Link>
          </div>
        );
      }
    }
    function St3images(s){

      const getSrvUpdates = (myQai2)=>{
        const ndt =new Date().getTime();
        fetchMyData(globalParams.apiUrl,myQai2,
        (newData)=>{
           newData.dt = ndt;
           setmyQai(newData);
        }
        );
    }

      const myStages = myQai?.stages || [];
      if (myStages.length>1){
      myQai.stages[myQai.stages.length-2]["selPic"] = s;
      myQai.stages[myQai.stages.length-2]["selDt"] = new Date().getTime();
      //setmyQai(JSON.parse(JSON.stringify(myQai)));
      getSrvUpdates(myQai);

      //myScrolly();
      }
      else {
        //getSrvUpdates(JSON.parse(JSON.stringify(myQai)));
      }
    }

      return {myQai, setmyQai, fetchMyData, St2images, Rateit, FormGender };
}

export default QAI;
